// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDoc, getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyBume28BEcKvqZjoR_8xP-uG8VWyOWWCu8",
  authDomain: "olaplex-433708.firebaseapp.com",
  projectId: "olaplex-433708",
  storageBucket: "olaplex-433708.firebasestorage.app",
  messagingSenderId: "927231069513",
  appId: "1:927231069513:web:a29b03df88cce4c4cf1e98",
  measurementId: "G-4TNL0MBJCT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);




// async function getSpyfuData() {
// 	const db = getFirestore(app);
// 	// getDoc(db, 'spyfuData');
// }
