import React, { useRef } from "react";
import "./App.css";
import EbayTablePage from "./pages/EbayTablePage";
import WalmartTablePage from "./pages/WallmartTablePage";
import { FirebaseAppProvider, FirestoreProvider, useFirebaseApp } from "reactfire";
import { getFirestore } from "firebase/firestore";
import { observer } from "mobx-react";
import { firebaseConfig } from "./FirebaseApp";
import SpyfuPage from "./pages/SpyfuPage";

const App: React.FC = observer(() => {
	const firestore = useRef(getFirestore(useFirebaseApp()));
	const firestoreInstance = firestore.current;
	const getPage = () => {
		if (location.href.includes("walmart")) {
			return <WalmartTablePage />;
		}
		if (location.href.includes("ebay")) {
			return <EbayTablePage />;
		}
		if (location.href.includes("spyfu")) {
			return <SpyfuPage />;
		}
		return <SpyfuPage />;
	};
	return <FirestoreProvider sdk={firestoreInstance}>{getPage()}</FirestoreProvider>;
});

const FirebaseApp: React.FC = () => {
	return (
		<FirebaseAppProvider firebaseConfig={firebaseConfig}>
			<App />
		</FirebaseAppProvider>
	);
};

export default FirebaseApp;
