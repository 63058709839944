import day0 from './data/ebay-1-11-24.json';
import day1 from './data/ebay-1-9-24.json';
import day2 from './data/ebay-10-10-24.json';
import day3 from './data/ebay-10-11-24.json';

export const allEbayDays = [day0, day1, day2, day3];

import wall_day0 from './data/wallmart-1-10-24.json';
import wall_day1 from './data/wallmart-14-8-24.json';
import wall_day2 from './data/wallmart-9-9-24.json';

export const allWallmarkDays = [wall_day0, wall_day1, wall_day2];

