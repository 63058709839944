import React, { useEffect } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { arrayUnion, collection, CollectionReference, doc, getDoc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import { observer } from "mobx-react";
import { logDebug } from "../logger";

interface KeywordStat {
	keyword: string;
	topRankedUrl: string;
	rank?: number;
	rankChange?: number;
	searchVolume?: number;
	keywordDifficulty?: number;
	broadCostPerClick?: number;
	phraseCostPerClick?: number;
	exactCostPerClick?: number;
	seoClicks?: number;
	seoClicksChange?: number;
	totalMonthlyClicks?: number;
	percentMobileSearches?: number;
	percentDesktopSearches?: number;
	percentNotClicked?: number;
	percentPaidClicks?: number;
	percentOrganicClicks?: number;
	broadMonthlyCost?: number;
	phraseMonthlyCost?: number;
	exactMonthlyCost?: number;
	paidCompetitors?: number;
	rankingHomepages?: number;
}

const KeywordStatKeysMapForZip: { [k: string]: string } = {
	keyword: "k",
	topRankedUrl: "t",
	rank: "r",
	rankChange: "rc",
	searchVolume: "sv",
	keywordDifficulty: "kd",
	broadCostPerClick: "bcc",
	phraseCostPerClick: "pcc",
	exactCostPerClick: "ecc",
	seoClicks: "sc",
	seoClicksChange: "scc",
	totalMonthlyClicks: "tmc",
	percentMobileSearches: "pms",
	percentDesktopSearches: "pds",
	percentNotClicked: "pnc",
	percentPaidClicks: "ppc",
	percentOrganicClicks: "poc",
	broadMonthlyCost: "bmc",
	phraseMonthlyCost: "pmc",
	exactMonthlyCost: "emc",
	paidCompetitors: "pc",
	rankingHomepages: "rh",
};
const KeywordStatKeysMapForZipReverse: { [k: string]: string } = {};
for (const key in KeywordStatKeysMapForZip) {
	KeywordStatKeysMapForZipReverse[KeywordStatKeysMapForZip[key]] = key;
}
interface ZippedKeywordStat {
	k?: string;
	t?: string;
	r?: number;
	rc?: number;
	sv?: number;
	kd?: number;
	bcc?: number;
	pcc?: number;
	ecc?: number;
	sc?: number;
	scc?: number;
	tmc?: number;
	pms?: number;
	pds?: number;
	pnc?: number;
	ppc?: number;
	poc?: number;
	bmc?: number;
	pmc?: number;
	emc?: number;
	pc?: number;
	rh?: number;
}

interface KeywordStats {
	results: KeywordStat[];
}
type ZippedKeywordStats = ZippedKeywordStat[];

function unzipKeywordStat(zipped: ZippedKeywordStat): KeywordStat {
	const keywordStat: KeywordStat = {} as KeywordStat;
	for (const key in zipped) {
		// @ts-ignore
		const value = zipped[key];
		const newKey = KeywordStatKeysMapForZipReverse[key];
		if (newKey) {
			// @ts-ignore
			keywordStat[newKey] = value;
		}
	}
	return keywordStat;
}
function unzipKeywordStats(zipped: ZippedKeywordStats): KeywordStat[] {
	const keywordStats: KeywordStats = { results: [] };
	for (const zippedStat of zipped) {
		keywordStats.results.push(unzipKeywordStat(zippedStat));
	}
	return keywordStats.results;
}

interface ZippedSpyfuData {
	id: string;
	spyfuData: {
		url: string;
		domainStats: {
			domain: string;
			results: {}[];
			resultsCount: number;
		};
		keywordStats: ZippedKeywordStats;
	}[];
}
interface SpyfuData {
	id: string;
	url: string;
	domainStats: {
		domain: string;
		results: {
			averageAdRank: number;
			averageOrganicRank: number;
			monthlyBudget: number;
			monthlyOrganicClicks: number;
			monthlyOrganicValue: number;
			monthlyPaidClicks: number;
			searchMonth: number;
			searchYear: number;
			strength: number;
			totalAdsPurchased: number;
			totalInverseRank: number;
			totalOrganicResults: number;
		}[];
		resultsCount: number;
	};
	keywordStats: KeywordStat[];
}

const SpyfuPage: React.FC = observer(() => {
	// const [ignoredUrls, setIgnoredUrls] = React.useState<string[]>([]);
	const [allSpyfuData, setAllSpyfuData] = React.useState<{ id: string; data: SpyfuData[] }[]>([]);
	const [latestSpyfuData, setLatestSpyfuData] = React.useState<{ id: string; data: SpyfuData[] }>();
	const [selectedSpyfuData, setSelectedSpyfuData] = React.useState<SpyfuData | null>(null);
	const firestoreToolsCollectionRef = collection(useFirestore(), `spyfu`) as CollectionReference<any>;
	const dataFromDataBase = useFirestoreCollectionData<any>(firestoreToolsCollectionRef, { idField: "id" });

	const nonDataIds = ["Latest", "Ignore"];

	useEffect(() => {
		if (dataFromDataBase.status === "success") {
			const ignore = dataFromDataBase.data.find((data: any) => data.id === "Ignore");
			const _ignoredUrls = (ignore?.ignoredUrls || []).map((url: string) => url.toLowerCase());
			// setIgnoredUrls(_ignoredUrls);

			const dataOnly = dataFromDataBase.data.filter((data: any) => !nonDataIds.includes(data.id)) as ZippedSpyfuData[];
			const unZipped = dataOnly.map((zipped: ZippedSpyfuData) => {
				const data = zipped.spyfuData
					.map((spyfuData) => {
						return {
							url: spyfuData.url,
							domainStats: spyfuData.domainStats,
							keywordStats: unzipKeywordStats(spyfuData.keywordStats),
						} as SpyfuData;
					})
					.filter((spyfuData) => !_ignoredUrls.includes(spyfuData.url.toLowerCase()));
				return {
					id: zipped.id,
					data,
				};
			});
			setAllSpyfuData(unZipped);

			const latest = dataFromDataBase.data.find((data: any) => data.id === "Latest");
			const latestKey = latest.latestDataPath.split("/").pop();
			const latestData = unZipped.find((data) => data.id === latestKey);
			setLatestSpyfuData({
				data: latestData?.data || [],
				id: latestKey || "",
			});
		}
	}, [dataFromDataBase.status, dataFromDataBase.data]);

	const _setSelectedSpyfuData = (data: SpyfuData) => {
		const dataClone = JSON.parse(JSON.stringify(data)) as SpyfuData;
		dataClone.keywordStats = dataClone.keywordStats
			.filter((c) => !!c.seoClicks)
			.sort((a, b) => (b.seoClicks || 0) - (a.seoClicks || 0));
		setSelectedSpyfuData(dataClone);
	};

	if (dataFromDataBase.status === "loading") {
		return <div>Loading...</div>;
	}

	async function ignoreURL(url: string) {
		const docRef = doc(getFirestore(), "spyfu", "Ignore");

		// Check if the document exists
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			// Update if it exists
			await updateDoc(docRef, {
				ignoredUrls: arrayUnion(url),
			});
		} else {
			// Create with initial array if it doesn’t exist
			await setDoc(docRef, {
				ignoredUrls: [url],
			});
		}
	}

	function getAccumulatedData(keywordStats: KeywordStat[], key: string): string {
		return parseInt(
			keywordStats
				// @ts-ignore
				.reduce((acc, curr) => acc + (curr[key] || 0), 0)
				.toFixed(0)
		).toLocaleString();
	}
	function getAverageData(keywordStats: KeywordStat[], key: string): string {
		return [
			keywordStats.reduce(
				(acc, curr) => {
					// @ts-ignore
					acc.acc += curr[key] || 0;
					// @ts-ignore
					acc.totalNonZero += curr[key] ? 1 : 0;
					return acc;
				},
				{ acc: 0, totalNonZero: 0 }
			),
		]
			.map(({ acc, totalNonZero }) => (totalNonZero ? acc / totalNonZero : 0))[0]
			.toFixed(2);
	}

	return (
		<div className="flex w-full justify-center">
			<div className="flex flex-col w-[90%] justify-center">
				{!selectedSpyfuData && (
					<div className="mt-4">
						<div className="flex w-full justify-start mb-4">
							<div className="flex">
								{[...allSpyfuData].reverse().map((data, index) => {
									return (
										<div
											key={data.id}
											className={`cursor-pointer hover:text-blue-500 mx-2 p-1 ${
												latestSpyfuData?.id === data.id ? "bg-gray-300 rounded-xl" : ""
											}`}
											onClick={() => {
												setLatestSpyfuData({ data: data.data, id: data.id });
											}}
										>
											{data.id.replace("SPYFU_", "").replaceAll("_", "/")}
										</div>
									);
								})}
							</div>
						</div>
						<table>
							<thead>
								<tr>
									<th>URL</th>
									<th>Total SEO Clicks </th>
									{/* <th>Broad Cost Per Click Average</th> */}
									<th>Total Broad Monthly Cost </th>
									{/* <th>Percent Organic Clicks Average</th> */}
									{/* <th>Percent Paid Clicks Average</th> */}
									<th>Total Monthly Clicks</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{latestSpyfuData?.data.map((data, index) => {
									return (
										<tr className={`p-2 ${index % 2 ? "bg-gray-200" : "bg-gray-100"}`} key={index}>
											<td
												className="cursor-pointer hover:text-blue-500"
												onClick={(e) => {
													if (e.ctrlKey || e.metaKey || e.shiftKey) {
														window.open(data.url, "_blank");
													} else {
														_setSelectedSpyfuData(data);
													}
												}}
											>
												{data.url}
											</td>
											<td className="text-right">{getAccumulatedData(data.keywordStats, "seoClicks")}</td>
											{/* <td>{getAverageData(data.keywordStats, "broadCostPerClick")}</td> */}
											<td className="text-right">${getAccumulatedData(data.keywordStats, "broadMonthlyCost")}</td>
											{/* <td>{getAverageData(data.keywordStats, "percentOrganicClicks")}</td> */}
											{/* <td>{getAverageData(data.keywordStats, "percentPaidClicks")}</td> */}
											<td className="text-right">{getAccumulatedData(data.keywordStats, "totalMonthlyClicks")}</td>
											<td className="cursor-pointer hover:text-red-500" onClick={() => ignoreURL(data.url)}>
												Ignore
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				)}
				{selectedSpyfuData && (
					<div className="mt-4">
						<div className="flex justify-between p-2 bg-gray-200">
							<div className="cursor-pointer hover:text-blue-500" onClick={() => setSelectedSpyfuData(null)}>
								Back
							</div>
							<div className="flex">
								{[...allSpyfuData].reverse().map((data, index) => {
									return (
										<div
											key={data.id}
											className={`cursor-pointer hover:text-blue-500 mx-2 p-1 ${
												latestSpyfuData?.id === data.id ? "bg-gray-300 rounded-xl" : ""
											}`}
											onClick={() => {
												setLatestSpyfuData({ data: data.data, id: data.id });
												const selected = data.data.find((d) => d.url === selectedSpyfuData.url);
												if (selected) {
													_setSelectedSpyfuData(selected);
												}
											}}
										>
											{data.id.replace("SPYFU_", "").replaceAll("_", "/")}
										</div>
									);
								})}
							</div>
							<div className="cursor-pointer  hover:text-red-500" onClick={() => ignoreURL(selectedSpyfuData.url)}>
								Ignore
							</div>
						</div>
						<div>Domain: {selectedSpyfuData.domainStats.domain}</div>
						<div>Results Count: {selectedSpyfuData.domainStats.resultsCount}</div>
						<div>Monthly Budget: {selectedSpyfuData.domainStats.results[0].monthlyBudget}</div>
						<div>Monthly Organic Clicks: {selectedSpyfuData.domainStats.results[0].monthlyOrganicClicks}</div>
						<div>Monthly Organic Value: {selectedSpyfuData.domainStats.results[0].monthlyOrganicValue}</div>
						<div>Monthly Paid Clicks: {selectedSpyfuData.domainStats.results[0].monthlyPaidClicks}</div>
						<div>Search Month: {selectedSpyfuData.domainStats.results[0].searchMonth}</div>
						<div>Search Year: {selectedSpyfuData.domainStats.results[0].searchYear}</div>
						<div>Strength: {selectedSpyfuData.domainStats.results[0].strength}</div>
						<div>Total Ads Purchased: {selectedSpyfuData.domainStats.results[0].totalAdsPurchased}</div>
						<div>Total Inverse Rank: {selectedSpyfuData.domainStats.results[0].totalInverseRank}</div>
						<div>Total Organic Results: {selectedSpyfuData.domainStats.results[0].totalOrganicResults}</div>
						<div className="flex justify-between flex-col p-2 bg-gray-100">
							<div>Keyword Stats</div>
							<table>
								<thead>
									<tr>
										<th>Keyword</th>
										<th>SEO Clicks</th>
										<th>Broad Cost Per Click</th>
										<th>Broad Monthly Cost</th>
										<th>Percent Organic Clicks</th>
										<th>Percent Paid Clicks</th>
										<th>SEO Clicks Change</th>
										<th>Total Monthly Clicks</th>
									</tr>
								</thead>
								<tbody>
									{
										<tr className={`bg-gray-200 py-2`}>
											<td>Accumulated Data</td>
											<td>T:{getAccumulatedData(selectedSpyfuData.keywordStats, "seoClicks")}</td>
											<td>AVG:{getAverageData(selectedSpyfuData.keywordStats, "broadCostPerClick")}</td>
											<td>T:{getAccumulatedData(selectedSpyfuData.keywordStats, "broadMonthlyCost")}</td>
											<td>AVG:{getAverageData(selectedSpyfuData.keywordStats, "percentOrganicClicks")}</td>
											<td>AVG:{getAverageData(selectedSpyfuData.keywordStats, "percentPaidClicks")}</td>
											<td>NA</td>
											<td>T:{getAccumulatedData(selectedSpyfuData.keywordStats, "totalMonthlyClicks")}</td>
										</tr>
									}
									{selectedSpyfuData.keywordStats.map((keywordStat, index) => {
										return (
											<tr key={index} className={`${index % 2 ? "bg-gray-200 py-2" : "bg-gray-100"}`}>
												<td>{keywordStat.keyword}</td>
												<td className="text-right">{keywordStat.seoClicks?.toLocaleString()}</td>
												<td className="text-right">{keywordStat.broadCostPerClick}</td>
												<td className="text-right">
													{keywordStat.broadMonthlyCost && `$${keywordStat.broadMonthlyCost?.toLocaleString()}`}
												</td>
												<td className="text-right">{keywordStat.percentOrganicClicks}</td>
												<td className="text-right">{keywordStat.percentPaidClicks}</td>
												<td className="text-right">{keywordStat.seoClicksChange}</td>
												<td className="text-right">{keywordStat.totalMonthlyClicks?.toLocaleString()}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				)}
			</div>
		</div>
	);
});

export default SpyfuPage;
